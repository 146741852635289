import { container, title } from "assets/jss/material-kit-react.jsx";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    maxWidth: "100%",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  '@media screen and (max-width: 600px)': {
    mainRaised: {
      // margin: "-60px 30px 0px",
      borderRadius: "6px",
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
  },
  '@media screen and (min-width: 601px)': {
    mainRaised: {
      margin: "-60px 30px 0px",
      borderRadius: "6px",
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
  },
  imgGrayscale: {
    filter: "grayscale(1)",
    "&:hover": {
      filter: "none !important"
    }
  },
  categoryHover: {
    transition: "0.3s",
    // color:"#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // filter: "grayscale(1)",
    "&:hover": {
      color: "#fff !important",
      background: "#2A3F9A",
      "& p": {        
        color: "#fff !important"
      }
    }
  }
};

export default landingPageStyle;
