/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, PermPhoneMsg, PermPhoneMsgOutlined, LibraryBooksOutlined, BusinessCenterOutlined, GamesOutlined, HomeOutlined, MoreVertOutlined, BusinessOutlined } from "@material-ui/icons";

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Home"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={HomeOutlined}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              Home
            </Link>,
            <a
              href="#"
              // target="_blank"
              className={classes.dropdownLink}
            >
              Insights
            </a>
          ]}
        />
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Link to="/" className={classes.navLink}>
          Home
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        {/* <AniLink swipe direction="down" to="/" className={classes.navLink}>
          Home
        </AniLink> */}
        <Link to="/" className={classes.navLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link  to="/about" className={classes.navLink}>
          About
        </Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <AniLink swipe direction="up" to="/about" className={classes.navLink}>
          About
        </AniLink>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link  to="/services" className={classes.navLink}>
          Services
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link  to="/products" className={classes.navLink}>
          Products
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link  to="/partners" className={classes.navLink}>
          Partners
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link  to="/insights/" className={classes.navLink}>
          Insights
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link  to="/contact" className={classes.navLink}>
          Contact
        </Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="#"
            // target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <FaTwitter/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#"
            // target="_blank"
            className={classes.navLink}
          >
            <FaFacebook/>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#"
            // target="_blank"
            className={classes.navLink}
          >
            <FaInstagram/>
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
